
export const app_secret_key = "somedome--&^!@8970";
//export const API_URL = "http://127.0.0.1:5000/";
//export const API_URL = "http://localhost:5001/";
export const API_URL = "https://portfolio-services.devsfolio.pro/";

export const APP_NAME = "Resumespot"
export const APP_Title = "ResumeSpot"


export const userName = "Masadaq Masood";
export const userDesignation = "Full Stack Developer";
export const userAvatar = "https://bslthemes.com/arter/wp-content/uploads/2020/09/face-1-small.jpg";

export const userToken = "dXNlcl90b2t"
export const userStatue = "^$$%%dXNlcl90b2t"
export const userActive = "^$$%%dXNlclwe$4590b2t"

export const webAppLink='http://localhost:3000/welcome/'
export const userProfilePublicLink='http://localhost:3000/welcome/';
