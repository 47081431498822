const ContactForm = () => {
  return(
      <>


          <form action="" method="post" className="">
              <div className="art-contact-form">
                  <div className="art-form-field">
                                          <span className="wpcf7-form-control-wrap" data-name="your-name">
                                              <input type="text" name="Name"  placeholder="Name" className="wpcf7-form-control wpcf7-text art-input"/>
                                              <span className="wpcf7-not-valid-tip"><small>The field is required.</small></span>
                                          </span>
                      <label className="">
                          <i className="fas fa-user"></i>
                      </label>
                  </div>

                  <div className="art-form-field">
                                          <span className="wpcf7-form-control-wrap" data-name="your-email">
                                              <input type="email" name="Email" placeholder="Email" className="wpcf7-form-control wpcf7-text art-input"/>
                                              <span className="wpcf7-not-valid-tip"><small>The field is required.</small></span>
                                          </span>
                      <label className="">
                          <i className="fas fa-at"></i>
                      </label>
                  </div>

                  <div className="art-form-field">
                                          <span className="wpcf7-form-control-wrap" data-name="your-message">
                                              <textarea name="your-message" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea art-input" placeholder="Message">
                                              </textarea>
                                              <span className="wpcf7-not-valid-tip"><small>The field is required.</small></span>
                                          </span>
                      <label className=""><i className="far fa-envelope"></i></label>
                  </div>
                  <div className="art-submit-frame">
                      <button className="art-btn art-btn-md art-submit" type="submit"><span>Send message</span>
                      </button>
                  </div>
              </div>
          </form>
      </>
  )
}
export default ContactForm;