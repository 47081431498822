const ResumeDetails = () => {

    return(
        <>
            <div className="col-md-9 col-sm-12">
                <div className="right-side-main-content">
                    <div className="about-us-content">
                        <h2>Resume</h2>
                    </div>
                    <div className="eduction-main-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="eduction-heading-content">
                                    <a href=""><img src="http://netgon.net/artstyles/v-card2/assets/icons/icon-education.svg" alt=""/>Education</a>
                                </div>
                                <div className="timeline_item">
                                    <h5>University School of the Arts</h5>
                                    <span>2007 — 2009</span>
                                    <p>Nemo enims ipsam voluptatem, blanditiis praesentium voluptum
                                        delenit atque corrupti, quos dolores et quas molestias exceptur.</p>
                                </div>
                                <div className="timeline_item">
                                    <h5>New York Academy of Art</h5>
                                    <span>2007 — 2009</span>
                                    <p>Nemo enims ipsam voluptatem, blanditiis praesentium voluptum
                                        delenit atque corrupti, quos dolores et quas molestias exceptur.</p>
                                </div>
                                <div className="timeline_item border-0">
                                    <h5>High School of Art and Design</h5>
                                    <span>2007 — 2009</span>
                                    <p>Duis aute irure dolor in reprehenderit in voluptate, quila
                                        voluptas mag odit aut fugit, sed consequuntur magni dolores eos.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="eduction-main-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="eduction-heading-content">
                                    <a href=""><img src="http://netgon.net/artstyles/v-card2/assets/icons/icon-experience.svg" alt=""/>Experience</a>
                                </div>
                                <div className="timeline_item">
                                    <h5>Creative Director</h5>
                                    <span>2007 — 2009</span>
                                    <p>Nemo enims ipsam voluptatem, blanditiis praesentium voluptum
                                        delenit atque corrupti, quos dolores et quas molestias exceptur.</p>
                                </div>
                                <div className="timeline_item">
                                    <h5>Art Director</h5>
                                    <span>2007 — 2009</span>
                                    <p>Nemo enims ipsam voluptatem, blanditiis praesentium voluptum
                                        delenit atque corrupti, quos dolores et quas molestias exceptur.</p>
                                </div>
                                <div className="timeline_item border-0">
                                    <h5>Web Designer</h5>
                                    <span>2007 — 2009</span>
                                    <p>Duis aute irure dolor in reprehenderit in voluptate, quila
                                        voluptas mag odit aut fugit, sed consequuntur magni dolores eos.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="eduction-main-content">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="eduction-heading-content">
                                    <a href="">Languages</a>
                                </div>
                                <div className="language-content">
                                    <form action="/action_page.php">
                                        <label htmlFor="customRange">Spanish</label>
                                        <input type="range" className="custom-range" id="customRange" name="points1"/>
                                    </form>
                                    <form action="/action_page.php">
                                        <label htmlFor="customRange">French</label>
                                        <input type="range" className="custom-range" id="customRange" name="points1"/>
                                    </form>
                                    <form action="/action_page.php">
                                        <label htmlFor="customRange">German</label>
                                        <input type="range" className="custom-range" id="customRange" name="points1"/>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="eduction-heading-content">
                                    <a href="">Knowledge</a>
                                </div>
                                <div className="knowledge-nav-list-content">
                                    <nav>
                                        <ul>
                                            <li><i className="fa-solid fa-check"></i> Bootstrap, Materialize</li>
                                            <li><i className="fa-solid fa-check"></i> Stylus, Sass, Less</li>
                                            <li><i className="fa-solid fa-check"></i> Gulp, Webpack, Grunt</li>
                                            <li><i className="fa-solid fa-check"></i> GIT knowledge</li>
                                            <li><i className="fa-solid fa-check"></i> AWS knowledge</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="eduction-main-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="eduction-heading-content mt-4">
                                    <a href="">My Skills</a>
                                </div>
                                <div className="skills-main-content">
                                    <div className="card">
                                        <div className="progress">
                                            <div className="progress-bar" style={{width:"80%"}}>
                                                <div className="progress-span-content">
                                                    <span>Web Design</span>
                                                    <span>80%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" style={{width:"75%"}}>
                                                <div className="progress-span-content">
                                                    <span>Graphic Design</span>
                                                    <span>75%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" style={{width:"90%"}}>
                                                <div className="progress-span-content">
                                                    <span>Photoshop</span>
                                                    <span>90%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" style={{width:"50%"}}>
                                                <div className="progress-span-content">
                                                    <span>Illustrator</span>
                                                    <span>50%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResumeDetails;