

const Coding = ({user_coding_skills}) => {
  return(
      <>
          <div className="languages_div_title">
              <h3>Coding</h3>
          </div>
          <div className="coding_div pb-4 mt-3">

              {user_coding_skills && user_coding_skills.map((skills,index)=>{
                  return <div>
                              <div className="coding_text">
                                  <p>{skills.name}</p>
                                  <p>{skills.count}%</p>
                              </div>
                              <div className="progress coding-progress-bar">
                                  <div className="progress-bar " style={{ width:skills.count+'%'}}></div>
                              </div>
                          </div>
              })}

          </div>
      </>
  )
}
export default Coding;
