import Recommendations from "./Recommendations";
import Services from "./Services";
const Home = () => {

    return(
        <>
            <div className="page_section_main_div">
                <div className="row">
                    <div className="col-md-12 pb-4 pt-3">


                        <div>
                            <div className="main_slider_section">
                                <div className="slider_avatar_main_div">
                                    <div className="p-2 mt-3">
                                        <h1>Discover my Amazing
                                            Art Space!</h1>
                                        <p><b className="code_view">&lt;code&gt;</b>
                                            {/*<span className="typewriter_container">*/}
                                            {/*typewriter*/}
                                                <span className="user_working_tag ">I build ios and dd android application </span>
                                            {/*</span>*/}
                                            <b className="code_view">&lt;code&gt;</b></p>

                                        <a className="btn btn-style">Explore now</a>
                                    </div>

                                    <div>
                                        <img src="https://bslthemes.com/arter/wp-content/uploads/2020/09/face-2-min.png"/>
                                    </div>
                                </div>


                            </div>
                        </div>


                        <div className="row mt-3 mb-3 pt-3">
                            <div className="col-md-3 col-6">
                                <div className="art-counter-frame">
                                    <div className="art-counter-box">
                                        <span className="art-counter">10</span>
                                        <span className="art-counter-plus">+</span>
                                    </div>
                                    <h6 className="art-counter-text">
                                        <span>Years Experience</span>
                                    </h6>
                                </div>
                            </div>

                            <div className="col-md-3 col-6">
                                <div className="art-counter-frame">
                                    <div className="art-counter-box">
                                        <span className="art-counter">143</span>
                                    </div>
                                    <h6 className="art-counter-text">
                                        <span>Completed Projects</span>
                                    </h6>
                                </div>
                            </div>

                            <div className="col-md-3 col-6">
                                <div className="art-counter-frame">
                                    <div className="art-counter-box">
                                        <span className="art-counter">114</span>
                                    </div>
                                    <h6 className="art-counter-text">
                                        <span>Happy Customers</span>
                                    </h6>
                                </div>
                            </div>

                            <div className="col-md-3 col-6">
                                <div className="art-counter-frame">
                                    <div className="art-counter-box">
                                        <span className="art-counter">20</span>
                                        <span className="art-counter-plus">+</span>
                                    </div>
                                    <h6 className="art-counter-text">
		      	                        <span>Honors and Awards</span>
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <p>Hello! I’m Donald Wellborn. Back-end & Frond-end developer from UK, London. I have rich experience in wordpress, also I am good at Magento. I love to talk with you about our unique.</p>


                        <Services/>

                        <Recommendations/>

                    </div>
                </div>
            </div>

        </>
    )
}
export default Home;
