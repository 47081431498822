import Index from "./themes/Netgon/Index";
import TestIndex from "./themes/Test/Index";
import ArterIndex from "./themes/Arter/Index";
import {useEffect, useState} from "react";
import {Auth_POST_Service} from "./services/API_Services";
import {GetUserTheme} from "./config/EndPoints";

const Main = () => {
    const [username, setUsername] = useState('');
    const pathname = window.location.pathname;

    const [theme_details, setTheme_details] = useState({
        'template_id':0,
        'color':'',
        'profile_id':0,
    });


    useEffect(()=>{

        const parts = pathname.split('/');

        if (parts.length > 1) {
            let user_details = parts[1];
            if(user_details){
                let data = {
                    'path':user_details
                }
                Auth_POST_Service(GetUserTheme, data).then(res=>{
                    if(res.status === 200){
                        let details = res.data.data;
                        let profile_details = details[0];

                        setTheme_details({
                            'template_id':profile_details.template_id,
                            'color':profile_details.color,
                            'profile_id':profile_details.profile_id,
                        })
                    };

                    if(res.status === 404){

                    }

                })
                setUsername(parts[1]);
            }

        }

    },[pathname])



    let theme =theme_details.template_id;

    return(
        <>
            {theme === 8 &&
                <ArterIndex  username={username} profile_id={theme_details.profile_id} color={theme_details.color}/>
            }


            {theme === 4 &&
                <TestIndex/>
            }

            {theme === 6 &&
                <Index/>
            }
        </>
    )
}

export default Main;
