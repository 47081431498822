import {userKnowledge} from '../../../services/UserSidebarServices';

const KnowledgeExperience = () => {
  return(
      <>
          <div className="languages_div pb-2 mt-3 border-0">
              <div>
                  <ul className="sidebar-knowledge-list">
                      {userKnowledge.map((knowledge,index)=>{
                          return <li><i className="fas fa-check"></i>{knowledge.name}</li>
                      })}
                  </ul>
              </div>
          </div>
      </>
  )
}
export default KnowledgeExperience;
