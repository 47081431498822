import {getStyleMargin} from "./Utility";

export const  toggleSidebar=()=>{
    let e = document.getElementById('side_bar_left');
    let left = getStyleMargin(e, 'margin-left');
    console.log(left);
    if(left === "-300px"){
        document.getElementById("side_bar_left").style.marginLeft = "0px";
    }else {
        document.getElementById("side_bar_left").style.marginLeft = "-300px";
    }
}


export const  toggleRightSidebar=()=>{
    let e = document.getElementById('side_bar_right_div');
    let left = getStyleMargin(e, 'margin-right');
    console.log(left);
    if(left === "-250px"){
        document.getElementById("side_bar_right_div").style.marginRight = "0px";
    }else {
        document.getElementById("side_bar_right_div").style.marginRight = "-250px";
    }
}
