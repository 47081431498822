const RecommendationCard = () => {
  return(
      <>
          <div className="swiper-slide ml-0 mr-2" >
              <div className="art-a art-testimonial mt-4">
                  <div className="testimonial-body">
                      <img decoding="async" alt="Paul Trueman"
                           className="art-testimonial-face lazyloaded"
                           src="https://bslthemes.com/arter/wp-content/uploads/2020/09/testimonial-1-140x140.jpg" />

                      <h5>
                          <span>Paul Trueman</span>
                      </h5>

                      <div className="art-el-suptitle mb-15">
                          <span>Template author</span>
                      </div>
                      <div className="mb-15 art-el-description">
                          <div>
                              <p>Working with Artur has been a pleasure. Better yet - I alerted
                                  them of a minor issue before going to sleep. The issue was fixed
                                  the next morning. I couldn't ask for better support. Thank you
                                  Artur! This is easily a 5 star freelancer.</p>
                          </div>
                      </div>
                  </div>

                  <div className="art-testimonial-footer">
                      <div className="art-left-side">
                          <ul className="art-star-rate">
                              <li><i className="fas fa-star"></i></li>
                              <li><i className="fas fa-star"></i></li>
                              <li><i className="fas fa-star"></i></li>
                              <li><i className="fas fa-star"></i></li>
                              <li><i className="fas fa-star"></i></li>
                          </ul>
                      </div>
                      <div className="art-right-side">
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}
export default RecommendationCard;