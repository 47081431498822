import '../Test/style/main.css';
const TestIndex = () => {

    return(
        <>
            <h1>Test Theme</h1>
        </>
    )
}

export default TestIndex;
