import '../../style/main.css';

const PortfolioCard=()=>{

    return(
        <>
            <div>
                <div className=" mb-4">
                    <div>
                        {/*<div className="p-0">*/}
                        {/*    <img className="img-fluid" src="https://imagekit.io/blog/content/images/2019/12/image-optimization.jpg"/>*/}
                        {/*</div>*/}
                        <div className="product-top">

                            <img style={{cursor:"pointer"}} className="img-fluid" src="https://imagekit.io/blog/content/images/2019/12/image-optimization.jpg"/>

                                <div className="overlay">
                                    <div className="hover-effect-content">
                                        <div className="hover-effect-heading">
                                            <p>Components</p>
                                            <h3>UI design</h3>
                                        </div>
                                        <div className="hover-effect-button">
                                            <a href=""> <i className="fa-solid fa-arrow-right"></i> </a>
                                        </div>
                                    </div>
                                </div>

                        </div>

                        <div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PortfolioCard;