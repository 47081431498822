import {codingSkils} from '../../../services/UserSidebarServices';

const CodingExperience = () => {
  return(
      <>
          <div className="coding_div pb-4 mt-3 border-0">

              {codingSkils.map((skills,index)=>{
                  return <div>
                              <div className="coding_text">
                                  <p>{skills.name}</p>
                                  <p>{skills.rating}%</p>
                              </div>
                              <div className="progress coding-progress-bar">
                                  <div className="progress-bar " style={{ width:skills.rating+'%'}}></div>
                              </div>
                          </div>
              })}

          </div>
      </>
  )
}
export default CodingExperience;
