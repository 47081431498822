import {useSelector} from "react-redux";

const Skills = () => {
    const {user_soft_skills} = useSelector(state => state.user);
  return(
      <>
        <div>
            {user_soft_skills && user_soft_skills.map((data, index)=>{
                return <span className="skills_bages">{data.name}</span>
            })}
        </div>
      </>
  )
}

export default Skills;