import {toggleRightSidebar, toggleSidebar} from "../utility/LayoutSetting";

const NavHeader = () => {
    return(
        <>
            <div className="top_bar_section">
                <div className="top_bar_section_div">
                    <div className="text-left">
                        <a className="art-info-bar-btn text-white" onClick={(e)=>toggleSidebar()}>
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                    </div>
                    <div className="text-right">
                        <a className="art-info-bar-btn text-white" onClick={()=>toggleRightSidebar()}>
                            <i className="fas fa-bars"></i>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
export default NavHeader;
