import RecommendationCard from "./components/RecommendationCard";
import Slider from "react-slick";


const Recommendations = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows:false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
    ]
    };

    return(
        <>
            <div>
                <div className="col-md-12 p-0">
                    <h2 className="page_title">Recommendations</h2>
                </div>

                <div className="col-md-12 p-0">
                    <Slider {...settings}>
                        <RecommendationCard/>
                        <RecommendationCard/>
                        <RecommendationCard/>
                    </Slider>


                </div>
            </div>
        </>
    )
}
export default Recommendations;