const LanguagesExperience = () => {
  return(
      <>
          <div className="languages_div_experience pb-2 mt-3">
              <div className="text_div text-center">
                  <div>
                      <div className="progress_div_s yellow">
                            <span className="progress_div_s-left">
                                <span className="progress_div_s-bar"></span>
                            </span>
                            <span className="progress_div_s-right">
                                <span className="progress_div_s-bar"></span>
                            </span>

                          <div className="progress_div_s-value">70%</div>
                      </div>
                  </div>
                  <p className="main_title_info">French</p>
              </div>
              <div className="text_div text-center">
                  <div>
                      <div className="progress_div_s yellow">
                            <span className="progress_div_s-left">
                                <span className="progress_div_s-bar"></span>
                            </span>
                          <span className="progress_div_s-right">
                                <span className="progress_div_s-bar"></span>
                            </span>

                          <div className="progress_div_s-value">90%</div>
                      </div>
                  </div>
                  <p className="main_title_info">English</p>
              </div>
              <div className="text_div text-center">
                  <div>
                      <div className="progress_div_s yellow">
                            <span className="progress_div_s-left">
                                <span className="progress_div_s-bar"></span>
                            </span>
                          <span className="progress_div_s-right">
                                <span className="progress_div_s-bar"></span>
                            </span>

                          <div className="progress_div_s-value">90%</div>
                      </div>
                  </div>
                  <p className="main_title_info">French</p>
              </div>
              <div className="text_div text-center">
                  <div>
                      <div className="progress_div_s yellow">
                            <span className="progress_div_s-left">
                                <span className="progress_div_s-bar"></span>
                            </span>
                          <span className="progress_div_s-right">
                                <span className="progress_div_s-bar"></span>
                            </span>

                          <div className="progress_div_s-value">90%</div>
                      </div>
                  </div>
                  <p className="main_title_info">French</p>
              </div>
          </div>
      </>
  )
}
export default LanguagesExperience;
