const ServiceCard = ({services}) => {

    return(
        <>
            <div>
                <div className="art-a art-service-icon-box">
                    <div className="art-service-ib-content">
                        <h5 className="mb-15">
                            <span>{services.name}</span>
                        </h5>

                        <div className="mb-15">
                            <div>
                                <p>{services.description}</p></div>
                        </div>
                        <div className="art-buttons-frame">
                            <a href={services.link}
                               className="art-link art-color-link art-w-chevron">
		        		        <span>Order now</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceCard;