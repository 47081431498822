import '../pages/style_pages/home.css';


const Sidebar = () => {
  return(
      <>
          <div className="sidebar-colum-content">
              <div className="card">
                  <div className="sidebar-profile-content text-center">
                      <img src="http://netgon.net/artstyles/v-card2/assets/img/avatar-1.jpg" alt=""/>
                      <h3>Felecia <span>Brown</span></h3>
                      <button><a href="sidebar">Creative Director</a></button>
                      <div className="sidebar-social-icon">
                          <i className="fa-brands fa-facebook-f"></i>
                          <i className="fa-brands fa-twitter"></i>
                          <i className="fa-brands fa-linkedin-in"></i>
                      </div>
                  </div>
                  <div className="sidebar-list-content">
                      <nav>
                          <ul>
                              <li><i className="fa-regular fa-calendar"></i> March 25, 1995</li>
                              <li><i className="fa-solid fa-location-dot"></i> San-Francisco, USA</li>
                              <li><a href="sidebar"><i className="fa-regular fa-message"></i> example@mail.com</a></li>
                              <li><i className="fa-solid fa-mobile"></i> +1 (070) 123-4567</li>
                              <li><a href="sidebar"><i className="fa-brands fa-skype"></i> Felecia_Brown</a></li>
                          </ul>
                      </nav>
                      <button><a href="sidebar"><i className="fa-solid fa-download"></i> Download CV</a></button>
                  </div>
              </div>
          </div>
      </>
  )
}

export default Sidebar;