import Info from "./sidebar_components/Info";
import Languages from "./sidebar_components/Languages";
import Coding from "./sidebar_components/Coding";
import Knowledge from "./sidebar_components/Knowledge";
import {userAvatar, userDesignation, userName} from "../config/Constant";
import {toggleSidebar} from "../utility/LayoutSetting";
import {useSelector} from "react-redux";
const Sidebar = () => {

    const {userAboutProfileDetails, address, user_coding_skills, user_language} = useSelector(state => state.user);

    return(
        <>
            <div>
                <div className="sidebar_section">
                    <div>
                        <a className="art-info-bar-btn text-white side_bar_close" onClick={()=>toggleSidebar()}>
                            <i className="fas fa-close"></i>
                        </a>
                    </div>
                    <div>
                        <div className="">

                            <div className="main_profile_div">
                                <div className="text-center p-3">
                                    <img src={userAboutProfileDetails.avatar_image} className="side_bar_profile_image"/>
                                    <h3 className="side_bar_profile_name">{userAboutProfileDetails.user_name}</h3>
                                    <p className="side_bar_profile_des">{userAboutProfileDetails.designation}</p>
                                </div>
                            </div>



                            <div>
                                <div className="list_div_main">

                                    <div>
                                        <div className="m-4">
                                            <Info address={address} />
                                        </div>


                                        <div className="m-4">
                                            <Languages user_language={user_language}/>
                                        </div>

                                        <div className="m-4">
                                            <Coding user_coding_skills={user_coding_skills}/>
                                        </div>

                                        <div className="m-4">
                                            <Knowledge />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div>
                                <div className="card profile_card_links">
                                    <div className="profile-sidebar-social">
                                        <a href="https://linkedin.com/" target="_blank">
                                            <i className="fab fa-linkedin"></i>
                                        </a>
                                        <a href="https://dribble.com/" target="_blank">
                                            <i className="fab fa-dribbble"></i>
                                        </a>
                                        <a href="https://behance.com/" target="_blank">
                                            <i className="fab fa-behance"></i>
                                        </a>
                                        <a href="https://github.com/" target="_blank">
                                            <i className="fab fa-github"></i>
                                        </a>
                                        <a href="https://twitter.com/" target="_blank">
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar;
