import axios from "axios";
import {API_URL} from "../config/Constant";

const request_config = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    withCredentials: false
}

const auth_request_config=()=> {
    const request_config = {
        headers: {
            'Accept': 'application/json',
            // 'Content-Type': 'application/json',
        },
        withCredentials: false
    }
    return request_config;

}


const success_checks=(response, app_name, app_action)=>{
    let message = ""
    if (response.status === 200){
        message = response.data.message

    }
    if (response.status === 202){
        message = response.data
    }
    if (response.status === 201){
        message = response.data.message
    }

    return {
        'data':response.data,
        'status':response.status
    };

}

const error_checks=(err)=>{
    if(err.response.status === 400){
        //alertNotification(app_name,app_action,false, err.response.data.message);
    }

    if(err.response.status === 404){
        //alertNotification(app_name,app_action,false, err.response.data.message);
    }

    if(err.response.status === 401){

    }

    if(err.response.status === 500){

    }

    return {
        'message':err.response.data.message,
        'status': err.response.status
    }
}


const success_res=(response,)=>{
    let data = ""
    let message = ""
    if (response.status === 200){
        data = response.data
    }
    if (response.status === 202){
        message = response.data
    }
    if (response.status === 201){
        message = response.data.message
    }

    return {
        'status':response.status,
        'data':data,
        'message':message
    };
}

export const Auth_GET_Service=async(url)=>{
    try {

        const result =  axios.get(API_URL+url,auth_request_config());
        const response = await result;
        return success_res(response);

    }catch (err){
        return error_checks(err);
    }
}

export const Auth_POST_Service=async(url,data)=>{
    try {
        const result = await axios.post(API_URL+url,data,auth_request_config());
        const response = await result;
        return  success_checks(response);

    }catch (err){
        return error_checks(err);
    }
}
