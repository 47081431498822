const Info = ({address}) => {

    return(
        <>
            <div className="info_div pb-3">
                <div className="text_div text-left">
                    <p className="main_title_info">Residence:</p>
                    <p className="main_title_info">City:</p>
                    <p className="main_title_info">Age:</p>
                </div>
                <div className="text_div text-right">
                    <p>{address.country}</p>
                    <p>{address.city}</p>
                    <p>25</p>
                </div>
            </div>
        </>
    )
}
export default Info;
