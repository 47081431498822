export const codingSkils = [
    {name:'Html', rating:'80'},
    {name:'CSS', rating:'80'},
    {name:'JavaScript', rating:'70'},
    {name:'Php', rating:'90'},
    {name:'Python', rating:'95'},
]

export const userKnowledge = [
    {name:'Bootstrap, Materialize'},
    {name:'Stylus, Sass, Less'},
    {name:'Gulp, Webpack, Grunt'},
    {name:'GIT knowledge'},
    {name:'AWS knowledge'},
]


export const userExperience = [
    {
        "year_time":"2017-2020",
        "company":"ABS",
        "job_type":"Full Stack",
        "designation":"php developer",
        "description":"Hello ther is new message for e re i iroier owir ioeur  o oewruo ru j l skjd ajd  dj oi i opeip aiepoeipo pipoiewo pieoqp pipoie p pieop iowqe ipoip  reir epori poeir p wpripoeri pi  pwir ppei poeiei priep p eripeer ii ",
        "keys":[
            "neoiuio djkjksdjf  dsf sfoie io eriopiope i",
            "neoiuio djkjksdjf  dsf sfoie io eriopiope e we",
            "neoiuio djkjksdjf  dsf sfoie io eriopiope e ewq e",
            "neoiuio djkjksdjf  dsf sfoie io eriopiope  ewe qe",
        ],
        'projects':[
            {
                "name":"ropstam.com",
                "link":"https://mksoltech.com"
            },
            {
                "name":"djangoproject.com ",
                "link":"https://abs.com"
            }
        ]
    },
    {
        "year_time":"2012-2024",
        "company":"ABS34",
        "job_type":"Full S3tack",
        "designation":"php 34developer",
        "description":"Hello 3 ther is new message for e re i iroier owir ioeur  o oewruo ru j l skjd ajd  dj oi i opeip aiepoeipo pipoiewo pieoqp pipoie p pieop iowqe ipoip  reir epori poeir p wpripoeri pi  pwir ppei poeiei priep p eripeer ii ",
        "keys":[
            "neoiuio djkjksdjf 43 dsf sfoie io eriopiope i",
            "neoiuio djkjksdjf 43 dsf sfoie io eriopiope e we",
            "neoiuio djkjksdjf we  dsf sfoie io eriopiope e ewq e",
            "neoiuio djkjksdjf  dsf sfoie io eriopiope  ewe qe",
        ],
        'projects':[
            {
                "name":"Abd weq",
                "link":"https://mksweoltech.com"
            },
            {
                "name":"Ab ere we ",
                "link":"https://awebs.com"
            }
        ]
    }
]
