import {useSelector} from "react-redux";

const Experience = () => {

  const {user_experience} = useSelector(state => state.user);


  return(
      <>
        <div>

            <div>
                {user_experience && user_experience.map((experience,index)=>{

                    return <div className="text-white experience_section_div">
                                <div className="start_div">
                                    <h2>{experience.start_date} - {experience.end_date}</h2>
                                    <h5>{experience.company}</h5>

                                </div>
                                <div className="divider"></div>
                                <div className="end_div">
                                    <h3>{experience.position}</h3>
                                    <p>{experience.details}</p>

                                    {(JSON.parse(experience.tasks)[0].tasks) ?
                                    <div>
                                        <p>Achievements/Tasks</p>
                                        <ul>
                                            {(JSON.parse(experience.tasks)).map((points,index)=>{
                                                return <li>{points.tasks}</li>
                                            })}
                                        </ul>
                                    </div>
                                        :
                                        <></>
                                    }

                                    {(JSON.parse(experience.project)[0].project) ?
                                    <div>
                                        <p>Projects</p>
                                        <ul>
                                            {(JSON.parse(experience.project)).map((points,index)=>{
                                                return <li>{points.project}</li>
                                            })}
                                        </ul>
                                    </div>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                })}

            </div>
        </div>
      </>
  )
}

export default Experience;
