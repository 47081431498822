

const Portfolio = () => {
  return(
      <>
          <div className="col-md-9 col-sm-12">
              <div className="right-side-main-content">
                  <div className="about-us-content">
                      <h2>Portfolio</h2>
                  </div>
                  <div className="portfolio-nav-content">
                      <nav>
                          <ul>
                              <li><a href="" style={{color:"#304CFD"}}>All</a></li>
                              <li><a href="">Concept</a></li>
                              <li><a href="">Design</a></li>
                              <li><a href="">Life</a></li>
                          </ul>
                      </nav>
                  </div>
                  <div className="portfolio-main-content">
                      <div className="row">
                          <div className="col-md-6 col-sm-12">
                              <div className="portfolio-design-content">
                                  <div className="portfolio-img-content">
                                      <img src="http://netgon.net/artstyles/v-card2/assets/img/image_01.jpg" alt="" className="img-fluid"/>
                                  </div>
                                  <h3>Half Avocado</h3>
                                  <p>Concept</p>
                              </div>
                              <div className="portfolio-design-content">
                                  <div className="portfolio-img-content">
                                      <img src="http://netgon.net/artstyles/v-card2/assets/img/image_03.jpg" alt="" className="img-fluid"/>
                                  </div>
                                  <h3>Half Avocado</h3>
                                  <p>Concept</p>
                              </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                              <div className="portfolio-design-content">
                                  <div className="portfolio-img-content">
                                      <img src="http://netgon.net/artstyles/v-card2/assets/img/image_02.jpg" alt="" className="img-fluid"/>
                                  </div>
                                  <h3>Half Avocado</h3>
                                  <p>Concept</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}

export default Portfolio;