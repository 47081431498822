import PortfolioCard from './PortfolioCard';
const Portfolio = () => {

    return(
        <>
            <div className="page_section_main_div">
                <div className="row">
                     <div className="col-md-12">
                         <h2 className="page_title">Portfolio</h2>
                     </div>
                    <div className="col-md-12 pb-4 pt-3">
                        <div className="row">
                            <div className="col-md-12"></div>

                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-4">
                                        <PortfolioCard/>
                                    </div>

                                    <div className="col-md-4">
                                        <PortfolioCard/>
                                    </div>

                                    <div className="col-md-4">
                                        <PortfolioCard/>
                                    </div>

                                    <div className="col-md-4">
                                        <PortfolioCard/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default Portfolio;