import Experience from "./components/Experience";
import Skills from "./components/Skills";
import LanguagesExperience from "./components/LanguagesExperience";
import CodingExperience from "./components/CodingExperience";
import KnowledgeExperience from "./components/KnowledgeExperience";
import EducationExperience from "./components/EducationExperience";
import AchievementsExperience from "./components/AchievementsExperience";
import CertificateExperience from "./components/CertificateExperience";
import ReferenceExperience from "./components/ReferenceExperience";
const Resume = () => {

    return(
        <>
            <div className="page_section_main_div resume_back_div">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="page_title">Resume</h2>
                        <p>11 Years of Experience</p>
                    </div>
                    <div className="col-md-12 pb-4 pt-3">
                        <div className="row">
                            <div className="col-md-8 pl-4 pr-4">
                                <div>
                                    <div>
                                        <div>
                                            <h2 className="page_title experience_section_title"><i className="fas fa-briefcase"></i>  Experience</h2>
                                        </div>
                                    </div>
                                    <Experience/>
                                </div>


                                <div>
                                    <div>
                                        <div>
                                            <h2 className="page_title experience_section_title">Education</h2>
                                        </div>
                                    </div>
                                    <EducationExperience/>
                                </div>


                                <div>
                                    <div>
                                        <div>
                                            <h2 className="page_title experience_section_title">Achievements</h2>
                                        </div>
                                    </div>
                                    <AchievementsExperience/>
                                </div>

                                <div>
                                    <div>
                                        <div>
                                            <h2 className="page_title experience_section_title">Certificate</h2>
                                        </div>
                                    </div>
                                    <CertificateExperience/>
                                </div>

                                <div>
                                    <div>
                                        <div>
                                            <h2 className="page_title experience_section_title">Reference</h2>
                                        </div>
                                    </div>
                                    <ReferenceExperience/>
                                </div>


                            </div>
                            <div className="col-md-4 pl-4 pr-4">
                                <div>
                                    <div>
                                        <div>
                                            <h2 className="page_title experience_section_title mb-4">Skills</h2>
                                        </div>
                                    </div>

                                    <Skills/>
                                </div>

                                <div>
                                    <div>
                                        <div>
                                            <h2 className="page_title experience_section_title mb-4 mt-5">Coding Skills</h2>
                                        </div>
                                    </div>

                                    <CodingExperience/>
                                </div>

                                <div>
                                    <div>
                                        <div>
                                            <h2 className="page_title experience_section_title mb-4 mt-5">Knowledge</h2>
                                        </div>
                                    </div>

                                    <KnowledgeExperience/>
                                </div>

                                <div>
                                    <div>
                                        <div>
                                            <h2 className="page_title experience_section_title mb-4 mt-5">Languages</h2>
                                        </div>
                                    </div>

                                    <LanguagesExperience/>
                                </div>

                                <div>
                                    <div>
                                        <div>
                                            <h2 className="page_title experience_section_title mb-4 mt-5">Interests</h2>
                                        </div>
                                    </div>

                                    <Skills/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Resume;
