import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo:{
    user:  "",
    id: "",
    email: "",
    first_name: "",
  },
  user_knowledge:[],
  user_services:[],
  user_experience:[],
  user_education:[],
  user_soft_skills:[],
  userAboutProfileDetails:[],
  address:[],
  user_coding_skills:[],
  user_language:[],
  user_achievements:[],
  user_certificates:[],
  user_reference:[],


  showknowledge:false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {

    setUserDetails:(state,action)=>{
        state.user_knowledge = action.payload.user_knowledge;
        state.showknowledge = action.payload.showknowledge;
        state.user_services = action.payload.user_services;
        state.user_experience = action.payload.user_experience;
        state.user_education = action.payload.user_education;
        state.user_soft_skills = action.payload.user_soft_skills;
        state.userAboutProfileDetails = action.payload.userAboutProfileDetails;
        state.address = action.payload.address;
        state.user_coding_skills = action.payload.user_coding_skills;
        state.user_language = action.payload.user_language;
        state.user_achievements = action.payload.user_achievements;
        state.user_certificates = action.payload.user_certificates;
        state.user_reference = action.payload.user_reference;
    },

    setUserProfile:(state,action)=>{
      state.userInfo.id = action.payload.id;
    },
    userLogout:(state)=>{
      state=null;
    },


    updateUserProfileAvatar:(state,action)=>{
      state.userInfo.avatar   = action.payload.avatar;
    },


  },
});

export const { setUserProfile,userLogout,updateUserProfileAvatar, setUserDetails } = userSlice.actions;

export default userSlice.reducer;
