const Languages = ({user_language}) => {
  return(
      <>
          <div className="languages_div_title">
              <h3>Languages</h3>
          </div>

          <div className="languages_div pb-2 mt-3">

              {user_language && user_language.map((details, index)=>{
                  return <div className="text_div text-center" key={index}>
                              <div>
                                  <div className="progress_div_s yellow">
                                    <span className="progress_div_s-left">
                                        <span className="progress_div_s-bar"></span>
                                    </span>
                                      <span className="progress_div_s-right">
                                        <span className="progress_div_s-bar"></span>
                                    </span>

                                      <div className="progress_div_s-value">{details.count}%</div>
                                  </div>
                              </div>
                              <p className="main_title_info">{details.name}</p>
                          </div>
              })}


          </div>
      </>
  )
}
export default Languages;
