import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import '../style/main.css';
import '../style/theme/black.css';
import '../style/mediaQuery.css';
import Sidebar from "../layouts/Sidebar";
import NavHeader from "../layouts/NavHeader";
import Home from "../pages/home/Home";
import About from "../pages/about/About";
import Navbar from "../layouts/Navbar";
import Portfolio from "../pages/portfolio/Portfolio";
import Contact from "../pages/contact/Contact";
import Resume from "../pages/resume/Resume";
import {useEffect, useState} from "react";
import {Auth_POST_Service} from "../../../services/API_Services";
import {GetUserDetails, GetUserTheme} from "../../../config/EndPoints";
import {UpdateState} from "../utility/Utility";
import {useDispatch} from "react-redux";
import {setUserDetails} from "../../../features/userSlice";

let color_check="yello";
if(color_check === "yello"){
    import( '../style/colors/yellow.css');
}

if(color_check === "red"){
    import( '../style/colors/red.css');
}

const RoutingServices = ({color, profile_id, username}) => {

    const dispatch = useDispatch();

    const [user_section_details, setUser_section_details] = useState({
        'user_knowledge': []
    });



    useEffect(()=>{
            if(profile_id){
                let data = {
                    'template_id':profile_id
                }
                Auth_POST_Service(GetUserDetails, data).then(res=>{
                    if(res.status === 200){
                        let details = res.data.message[0];
                        // let profile_details = details[0];
                       let user_knowledge = JSON.parse(details.user_knowledge) ? JSON.parse(details.user_knowledge) : [];
                       let user_services = JSON.parse(details.user_services) ? JSON.parse(details.user_services) : [];
                       let user_experience = JSON.parse(details.user_experience) ? JSON.parse(details.user_experience) : [];
                       let user_education = JSON.parse(details.user_education) ? JSON.parse(details.user_education) : [];
                       let user_soft_skills = JSON.parse(details.user_soft_skills) ? JSON.parse(details.user_soft_skills) : [];

                       let userAboutProfileDetails = JSON.parse(details.userAboutProfileDetails) ? JSON.parse(details.userAboutProfileDetails) : [];
                       let address = JSON.parse(details.address) ? JSON.parse(details.address) : [];
                       let user_coding_skills = JSON.parse(details.user_coding_skills) ? JSON.parse(details.user_coding_skills) : [];
                       let user_language = JSON.parse(details.user_language) ? JSON.parse(details.user_language) : [];
                       let user_achievements = JSON.parse(details.user_achievements) ? JSON.parse(details.user_achievements) : [];
                       let user_certificates = JSON.parse(details.user_certificates) ? JSON.parse(details.user_certificates) : [];
                       let user_reference = JSON.parse(details.user_reference) ? JSON.parse(details.user_reference) : [];

                       let showknowledge = (details.showknowledge);

                        UpdateState('user_knowledge',details.user_knowledge, setUser_section_details);
                        // setTheme_details({
                        //     'template_id':profile_details.template_id,
                        //     'color':profile_details.color,
                        //     'profile_id':profile_details.profile_id,
                        // })
                        dispatch(setUserDetails({
                            user_knowledge,
                            showknowledge,
                            user_services,
                            user_experience,
                            user_education,
                            user_soft_skills,
                            userAboutProfileDetails,
                            address,
                            user_coding_skills,
                            user_language,
                            user_achievements,
                            user_certificates,
                            user_reference
                        }))
                    };

                    if(res.status === 404){

                    }

                })
            }

    },[profile_id])
    return(
        <>
            <div>
                <div>
                    <div className="main_div_section">
                        <div className="side_bar_left" id="side_bar_left">
                            <Sidebar />
                        </div>
                        <div className="main_bar_right">
                            <div className="main_div_right">
                                <div>
                                    <NavHeader />
                                </div>
                                {/*Pages*/}

                                <BrowserRouter>
                                    <Routes>
                                        <Route path="/:username" element={<Home />} exact></Route>
                                        <Route path="/:username/about" element={<About />}></Route>
                                        <Route path="/:username/portfolio" element={<Portfolio />}></Route>
                                        <Route path="/:username/contact" element={<Contact />}></Route>
                                        <Route path="/:username/resume" element={<Resume />}></Route>
                                    </Routes>


                                    <Navbar username={username}/>
                                </BrowserRouter>
                                {/*Pages*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoutingServices;
