import {useState} from "react";
import {NavLink} from "react-router-dom";
import {toggleRightSidebar} from "../utility/LayoutSetting";
const Navbar = ({username}) => {
    const [currentPage, setCurrentPage] = useState("Home");
    return(
        <>
            <div>
                <div className="main_right_navbar">
                    <div>
                        <div>
                            <a href="#" className="art-info-bar-btn text-white" onClick={()=>toggleRightSidebar()}>
                                <i className="fas fa-bars"></i>
                            </a>
                        </div>
                        <div>
                            <p className="navbar_title_text">{currentPage}</p>
                        </div>
                    </div>
                </div>

                <div className="nav_bar_right_side" id="side_bar_right_div">
                    <div className="mb-4">
                        <div>
                            <a href="#" className="art-info-bar-btn text-white" onClick={()=>toggleRightSidebar()}>
                                <i className="fas fa-close"></i>
                            </a>
                        </div>
                    </div>
                    <ul >
                        <li>
                            <NavLink to={`/${username}`} onClick={(e)=>setCurrentPage("Home")}>
                                Home
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to={`/${username}/about`} onClick={(e)=>setCurrentPage("About")}>
                                About
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to={`/${username}/resume`} onClick={(e)=>setCurrentPage("Resume")}>
                                Resume
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to={`/${username}/portfolio`} onClick={(e)=>setCurrentPage("Portfolio")}>
                                Portfolio
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="/contact" onClick={(e)=>setCurrentPage("Contact")}>
                                Contact
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
export default Navbar;
