

const Navbar = ({selectTab}) => {
  return(
      <>
          <section className="navbar-main-content">
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-md-4">
                      </div>
                      <div className="col-md-8 pr-0 col-sm-12 text-right">
                          <div className="navbar-content">
                              <nav>
                                  <ul>
                                      <li><a onClick={(e)=>selectTab('About')} href="#">ABOUT</a></li>
                                      <li><a onClick={(e)=>selectTab('Resume')} href="#">RESUME</a></li>
                                      <li><a href="navbar" onClick={(e)=>selectTab('Portfolio')} href="#">PORTFOLIO</a></li>
                                      <li><a href="navbar" onClick={(e)=>selectTab('Contact')} href="#">CONTACT</a></li>
                                  </ul>
                              </nav>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </>
  )
}

export default Navbar;