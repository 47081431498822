const About = () => {
  return(
      <>
          <div className="page_section_main_div">
              <div className="row">
                  <div className="col-md-12">
                      <h2 className="page_title">About</h2>
                  </div>
                  <div className="col-md-12 pb-4 pt-3">
                  </div>
              </div>
          </div>
      </>
  )
}

export default About;
